import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Bot",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/livechat.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Chatbot`}</h1>
    <p>{`Create a chatbot for your Website`}</p>
    <hr></hr>
    <p>{`gallery`}</p>
    <p>{`Create complex chat response scenarios with Crisp chatbot without human effort. Looking to create a chatbot for your website ? This integration provides an easy to use IDE that makes chatbot building easy. You don't need any coding skills.`}</p>
    <h2>{`Benefits of creating a chatbot for your website:`}</h2>
    <ul>
      <li parentName="ul">{`Automate first-level support`}</li>
      <li parentName="ul">{`Build advanced scenarios based on user answers`}</li>
      <li parentName="ul">{`Create Human-like experiences with GIFs, typing indicators, file sharing`}</li>
      <li parentName="ul">{`Send predefined choices with button pickers`}</li>
      <li parentName="ul">{`Automate data enrichment through users interactions`}</li>
      <li parentName="ul">{`Combine chatbot with marketing and sales automation to put your customer journey on autopilot`}</li>
      <li parentName="ul">{`Assign automatically segment to users through conversational experience`}</li>
      <li parentName="ul">{`Bet on conversational experience to delight your customers`}</li>
      <li parentName="ul">{`Delete all your website's forms and replace them by chatbots`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      